.Body {
    color: rgba(0,0,0,.5);
    /* background-color: white; */
    text-align: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
}

.carousel-item {
    position: relative !important;
    height: 550px
}

.img {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
}

.image-overlay {
    position: absolute;
    /* opacity: 0.5; */
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-image: url(../transparent2.png);
    /* background: rgba(0, 0, 0, 0.6) */
}

p {
    color: rgba(0,0,0,.5);
    /* color: white;
    text-shadow: 2px 2px rgba(0,0,0,.5); */
}

.description {
    color: rgba(0,0,0,.5);
    text-shadow: none;
}

.carousel-caption {
    bottom: -38px;
}

@media screen and (max-width: 415px) {
    .img {
        top: 30%;
        width: 100%;
    }

    p {
        color: rgba(0,0,0,.5);
        text-shadow: none;
    }

    .carousel-caption {
        bottom: 65px;
    }

    .profile-pic {
        width: 100%;
    }
}

@media screen and (min-width: 416px) and (max-width: 640px) {
    .img {
        width: 100%
    }
}

@media screen and (min-width: 641px) and (max-width: 1450px) {
    .img {
        width: 41%
    }
}

@media screen and (min-width: 1451px) and (max-width: 2000px) {
    .img {
        width: 30%
    }
}