body {
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif;
  background-image: url('./white_brick_wall.png');
  background-repeat: repeat;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Noto Sans JP', sans-serif;
}
